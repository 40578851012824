<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App',
  mounted() {
    document.title = '小说,小说网,最新热门小说';
  }
}
</script>
